import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import NewsPreview from './NewsPreview'
import Navbar from './Navbar'
import Content from './Content'
import Footer from './Footer'
import Helmet from 'react-helmet'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './CustomForm'
import "typeface-playfair-display"
import "typeface-roboto"
import "./all.sass"

import ImageBarFull from './ImageBarFull'
  
const HomePageTemplate = ({
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content

return (
  < >
    <Helmet title="Grand Lakes MUD No. 4" />
    <Helmet>
      <meta name="google-site-verification" content="Qoxj4Hlzxo9ST0EsOtRgfo8wnSStUJIgr_Bo3HEibeI" />
    </Helmet>
    <div className="">
    <Navbar />
    <ImageBarFull />
    <section className="section__home-page">
      <div className="container container__homepage">
        <h2 className="news-preview-grid-title">What's New</h2> <div className="news-section news-full-grid">
                 <NewsPreview />
          <Link to="/latest-updates" className="updates">
              See All<br />
              Latest Updates<br />
              <div className="arrow"></div>
          </Link>

          <div className="eNews">
                <h3 className="is-size-2" style={{ marginBottom: '1rem'}}>Signup to receive updates straight to your inbox!</h3>
                <MailchimpSubscribe
                  url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=5a966aa4be"
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
          </div>
        </div>
      </div>
    </section>
    </div>
    <section className="section">
      <div className="container">
      <div className="columns">
        <div className="column is-7 is-offset-1 ">
          <div className="content homepage-content">
            <PostContent content={content} />
          </div>
        </div>
        <div className="column is-3 is-offset-1 vertical-justify">
            {/* <div className="panel has-l10n-text has-text-left offset-3">
              <p style={{ margin: 0 }}className="panel-heading">
                Election Information
              </p>
              <Link className="panel-block" to="/en/board-of-directors-elections">Board of Directors Elections</Link>
              <Link className="panel-block" to="/es/board-of-directors-elections">Elecciones de la Junta Directiva</Link>
            </div> */}
          {/* <Image className="asideImage" fixed={welcomeAside.childImageSharp.fixed} alt="" /> */}
        </div>
      </div>
      </div>
    </section>
    <Footer />
  </ >
)}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  welcomeMessage: PropTypes.string,
}

export default HomePageTemplate
